import React from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { canAccessTheRouteWithUserAuthorities, isAuthenticated, isRestrictedRoutWithAuthority, setRedirectLoginCallback } from "../utils/authGuard";
import Layout from "../components/layout/Layout";  // Import the Layout HOC
import Login from "../pages/login/Login";
import Index from "../pages/Index";
import { NoMatch } from "../pages/NoMatch";
import { PageUrls } from "../utils/constants";


import UserOnboardStepper from "../pages/user-registration/user-onboard/UserOnboardStepper";
import UserRegistration from "../pages/user-registration/UserRegistration";
import HorizontalLinearStepper from "../pages/user-registration/user-onboard/Stepper";
import ESignClientRequestDetail from "../pages/eSignClient/ESignClientRequestDetail";
import ESignOtpVerification from "../pages/eSignClient/ESignOtpVerification";
import ESignClientAgreement from "../pages/eSignClient/ESignClientAgreement";
import FundRequest from "../pages/funds/FundRequest";
import ResetPasswordDialog from "../pages/login/ResetPasswordDilouge";
import Dashboard from "../pages/dashboard/dashBoard";


function PrivateRoute({ element }) {
  const location = useLocation();
  if (!isAuthenticated()) {
    return <Navigate to={PageUrls.LOGIN} />;
  }
  if (isRestrictedRoutWithAuthority(location.pathname) && !canAccessTheRouteWithUserAuthorities(location.pathname)) {
    return <Navigate to={PageUrls.UNAUTHORIZED} />
  }
  return element;
}

const Routers = () => {
  const navigate = useNavigate();
  setRedirectLoginCallback(() => {
    navigate(PageUrls.LOGIN);
  });

  return (
    <Routes>
      <Route path={PageUrls.LOGIN} element={<Login />} />
      <Route path={PageUrls.INDEX} element={<Index />} />
      <Route path={PageUrls.USER_REGISTRATION} element={<UserRegistration />} />
      <Route path={PageUrls.USER_ONBOARD} element={<UserOnboardStepper />} />
      <Route path={`${PageUrls.USER_ONBOARD}/:token`} element={<UserOnboardStepper />} />
      <Route path={PageUrls.OTP_VERIFICATION} element={<ESignOtpVerification />} />
      <Route path={PageUrls.ESIGNREQ_CLIENT} element={<ESignClientRequestDetail />} />
      <Route path={PageUrls.CLIENT_AGREEMENT} element={<ESignClientAgreement />} />
      <Route path={PageUrls.FundRequest} element={<PrivateRoute element={<Layout><FundRequest/></Layout>} />} />
      <Route path={PageUrls.Dashboard} element={<PrivateRoute element={<Layout><Dashboard/></Layout>} />} />
      <Route path={PageUrls.RESET_PASSWORD} element={<ResetPasswordDialog />} />
      <Route path="*" element={<NoMatch />} />
    </Routes> 
  )
}

export default Routers;