import React, { useState } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import ESignClientRequestDetail from '../../pages/eSignClient/ESignClientRequestDetail';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ESignCompleteModal = ({ open, setIsOpen, onClose, setIsPreview }) => {
    const handleClose = () => {
        onClose();
    };
    const handlePreview = () => {
        setIsPreview(true)
        onClose();
    }
    return (<> <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                eSign Complete
            </Typography>
            <Button
                variant="contained"
                onClick={handlePreview}
                sx={{ mt: 2 }}
            >
                Preview Document
            </Button>
        </Box>
    </Modal></>

    );
};

export default ESignCompleteModal;
