import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  Typography,
  IconButton,
  Box,
  Divider,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useFormik } from "formik";
import * as Yup from "yup"; // For validation schema
import fetcher from "../../utils/fetcher"; // Assuming fetcher is already defined

const ResetPasswordDialog = ({ open, onClose }) => {
  const [captchaText, setCaptchaText] = useState("");

  // Function to generate Captcha
  const generateCaptcha = () => {
    const randomString = Math.random().toString(36).substring(2, 8).toUpperCase();
    setCaptchaText(randomString);
  };

  // Generate Captcha on component load
  useEffect(() => {
    generateCaptcha();
  }, []);

  // Formik for form handling
  const formik = useFormik({
    initialValues: {
      mobile: "",
      aadhaarNumber: "",
      panNumber: "",
      captchaCode: "",
    },
    validationSchema: Yup.object({
      mobile: Yup.string()
        .matches(/^\d{10}$/, "Mobile number must be 10 digits")
        .required("Mobile number is required"),
      aadhaarNumber: Yup.string()
        .matches(/^\d{12}$/, "Aadhaar number must be 12 digits")
        .nullable()
        .notRequired(),
      panNumber: Yup.string()
        .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN format (e.g., ABCDE1234F)")
        .nullable()
        .notRequired(),
      captchaCode: Yup.string()
        .oneOf([captchaText], "Captcha does not match")
        .required("Captcha code is required"),
    }).test(
      "aadhaar-or-pan",
      "Either Aadhaar Number or PAN Number is required",
      (values) =>
        (values.aadhaarNumber && values.aadhaarNumber.trim()) || 
        (values.panNumber && values.panNumber.trim())
    ),
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        const response = await fetcher.post("/reset-password/generate", values);
        alert("Password reset request successful!");
        onClose(); // Close dialog on success
      } catch (error) {
        setErrors({ apiError: "Failed to submit form. Please try again." });
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth  sx={{
        "& .MuiDialog-paper": {
          overflow: "hidden", // Prevent unnecessary scrollbars
        },
      }}    > 
      <DialogTitle >Reset Password</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {/* Mobile Number */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Registered Mobile Number"
                variant="outlined"
                name="mobile"
                value={formik.values.mobile}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                helperText={formik.touched.mobile && formik.errors.mobile}
              />
            </Grid>

            {/* Aadhaar Number */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Entity Aadhaar Number"
                variant="outlined"
                name="aadhaarNumber"
                value={formik.values.aadhaarNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.aadhaarNumber &&
                  Boolean(formik.errors.aadhaarNumber)
                }
                helperText={
                  formik.touched.aadhaarNumber && formik.errors.aadhaarNumber
                }
              />
            </Grid>

            {/* OR Divider */}
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" justifyContent="center" mt={-2} mb={-2}>
                <Divider flexItem style={{ width: "100%" }} />
                <Typography variant="body2" style={{ margin: "0 10px" }}>
                  OR
                </Typography>
                <Divider flexItem style={{ width: "100%" }} />
              </Box>
            </Grid>

            {/* PAN Number */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Entity PAN Number"
                variant="outlined"
                name="panNumber"
                value={formik.values.panNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.panNumber && Boolean(formik.errors.panNumber)
                }
                helperText={
                  formik.touched.panNumber && formik.errors.panNumber
                }
              />
            </Grid>

            {/* Captcha */}
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                Enter Captcha Code
              </Typography>
              <Box display="flex" alignItems="center" gap={1}>
                <Box
                  sx={{
                    padding: "8px 16px",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    fontWeight: "bold",
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  {captchaText}
                </Box>
                <IconButton onClick={generateCaptcha} color="primary" aria-label="refresh captcha">
                  <RefreshIcon />
                </IconButton>
              </Box>
              <TextField
                fullWidth
                margin="normal"
                placeholder="Enter CAPTCHA"
                name="captchaCode"
                value={formik.values.captchaCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.captchaCode &&
                  Boolean(formik.errors.captchaCode)
                }
                helperText={
                  formik.touched.captchaCode && formik.errors.captchaCode
                }
              />
            </Grid>

            {/* API Error */}
            {formik.errors.apiError && (
              <Grid item xs={12}>
                <Typography color="error">{formik.errors.apiError}</Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            sx={{
                backgroundColor: formik.isValid ? "#06226A" : "#ccc",
                "&:hover": {
                  backgroundColor: formik.isValid ? "#041947" : "#ccc",
                },
              }}
            disabled={formik.isSubmitting}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ResetPasswordDialog;
