import React, { useState } from 'react';
import global from '../../../assets/scss/global.module.scss'
import UserRegistrations from '../UserRegistrations.module.scss';
import useronboard from './UserOnboard.module.scss';


const WithGstNumber = ({ businssDetails, onClose }) => {
    const [isDisabled, setIsDisabled] = useState(true);

    const toggleDisable = () => {
        setIsDisabled(!isDisabled);
    };
    const handleSubmit = () => {
        // Hide the component on submit
        onClose();
    };

    return (
        <div className={global.overlay}>
            <div className={`${UserRegistrations.opt_screen} ${UserRegistrations.opt_screen_large}`}>
                <div className={UserRegistrations.opt_screen_large_pad}>
                    <div className={global.row}>
                        <div className={global.col_s_6}>
                           
                            <div className={`${global.form_group}`}>
                                <label>Business Name</label>
                                <input className={`${global.form_control} ${global.disbled_bg}`} placeholder='Tramo' type='text' value={businssDetails.businessName}  readOnly />
                            </div>

                            <div className={`${global.form_group}`}>
                                <label>Constitution type</label>
                                <input className={`${global.form_control} ${global.disbled_bg}`} placeholder='Sole Proprietorship' type='text' value={businssDetails.constitutionType}  readOnly />
                            </div>

                            <div className={global.row}>
                                <div className={global.col_s_6}>

                                    <div className={`${global.form_group}`}>
                                        <label>Registration Date</label>
                                        <input className={`${global.form_control} ${global.disbled_bg}`} placeholder='Registration Date' type='text' value={businssDetails.registrationDate}  readOnly />
                                    </div>

                                    <div className={`${global.form_group}`}>
                                        <label>Status</label>
                                        <input className={`${global.form_control} ${global.disbled_bg}`} placeholder='Status' type='text' value={businssDetails.status}  readOnly />
                                    </div>
                                   
                                    <div className={`${global.form_group}`}>
                                        <label>Business Incorporation Date </label>
                                        <input className={`${global.form_control} ${global.disbled_bg}`} placeholder='Incorporation Date' type='text' value={businssDetails.incorporationDate}  readOnly />
                                    </div>
                                    
                                </div>
                                
                                <div className={global.col_s_6}>
                                    <div className={`${global.form_group}`}>
                                        <label>State Code</label>
                                        <input className={`${global.form_control} ${global.disbled_bg}`} placeholder='State Code' type='text' value={businssDetails.stateCode}   readOnly />
                                    </div>

                                    <div className={`${global.form_group}`}>
                                        <label>Centre</label>
                                        <input className={`${global.form_control} ${global.disbled_bg}`} placeholder='Centre' type='text' value={businssDetails.center}  readOnly />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={global.col_s_6}>
                            <div className={`${global.form_group}`}>
                                <label>Business Registered Address 1</label>
                                <input className={`${global.form_control} ${global.disbled_bg}`} placeholder='Business Registered Address 1' value={businssDetails.address1} readOnly type='text' />
                            </div>

                            <div className={`${global.form_group}`}>
                                <label>Business Registered Address 2</label>
                                <input className={`${global.form_control} ${global.disbled_bg}`} placeholder='Business Registered Address 2' value={businssDetails.address2} readOnly type='text' />
                            </div>

                            <div className={global.row}>
                                <div className={global.col_s_6}>
                                    
                                    <div className={`${global.form_group}`}>

                                        <label>Pin Code</label>
                                        <input className={`${global.form_control} ${global.disbled_bg}`} placeholder='Pin Code' type='text' value={businssDetails.pinCode} readOnly/>

                                    </div>
                                </div>
                            </div>

                            <div className={global.row}>
                                <div className={global.col_s_6}>
                                    <div className={`${global.form_group}`}>
                                        <label>City/Village/Town</label>
                                        <input className={`${global.form_control} ${global.disbled_bg}`} placeholder='City/Village/Town' type='text' value={businssDetails.city} readOnly/>
                                    </div>

                                    <div className={`${global.form_group}`}>
                                        <label>District </label>
                                        <input className={`${global.form_control} ${global.disbled_bg}`} disabled={isDisabled} placeholder='District ' type='text' value={businssDetails.district} readOnly/>
                                    </div>
                                </div>
                                <div className={global.col_s_6}>
                                    <div className={`${global.form_group}`}>
                                        <label>State jurisdiction</label>
                                        <input className={`${global.form_control} ${global.disbled_bg}`} disabled={isDisabled} placeholder='State jurisdiction' type='text' value={businssDetails.stateJurisdiction} readOnly/>
                                    </div>

                                    <div className={`${global.form_group}`}>
                                        <label>Country</label>
                                        <input className={`${global.form_control} ${global.disbled_bg}`} disabled={isDisabled} placeholder='Country' type='text' value={businssDetails.country} readOnly/>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>  </div>
                <div className={useronboard.submit_text_bg}>
                    <input type='submit' onClick={handleSubmit} className={`${global.submit_primary_btn} ${global.display_inline_blk}`} value="Submit" />
                </div>
            </div>


        </div>
    )
}

export default WithGstNumber