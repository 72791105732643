import React, { useState } from 'react';
import {
  Grid,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Snackbar,
  Alert,
  Tabs,
  Tab,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import fetcher from '../../utils/fetcher';
import { ApiUrls } from '../../utils/constants';


const bankAccounts = [
    {
      bank: 'HDFC Bank',
      accountHolder: 'Tramo Technolab Pvt. Ltd.',
      accountNumber: '123456789012',
      accountType: 'Current',
      ifscCode: 'HDFC0001234',
      branch: 'Noida, UP',
      minAmount: '₹10,00,000',
      allowedModes: ['RTGS', 'NEFT'],
    },
    {
      bank: 'SBI Bank',
      accountHolder: 'Tramo Technolab Pvt. Ltd.',
      accountNumber: '987654321098',
      accountType: 'Savings',
      ifscCode: 'SBIN0005678',
      branch: 'Delhi, Connaught Place',
      minAmount: '₹5,00,000',
      allowedModes: ['RTGS', 'IMPS', 'NEFT'],
    },
  ];

  const transactions = [
    {
      date: '2024-04-06',
      time: '14:30:00',
      executionDate: '2024-04-06',
      executionTime: '14:45:00',
      transactionID: '12345',
      bank: 'HDFC Bank',
      amount: '₹1,00,000',
      transferDate: '2024-04-06',
      branchUTR: 'HDFC12345XYZ',
      commissionCharge: '₹500',
      depositSlip: 'HDFC-DS-98765',
      remarks: 'Payment for services',
      executersRemark: 'Service payment processed',
      status: 'Approved',
    },
    {
      date: '2024-04-07',
      time: '10:00:00',
      executionDate: '2024-04-07',
      executionTime: '10:15:00',
      transactionID: '67890',
      bank: 'SBI Bank',
      amount: '₹2,00,000',
      transferDate: '2024-04-07',
      branchUTR: 'SBI67890ABC',
      commissionCharge: '₹1000',
      depositSlip: 'SBI-DS-12345',
      remarks: 'Invoice payment',
      executersRemark: 'Payment pending confirmation',
      status: 'Pending',
    },
  ];
  
const FundRequest = () => {
  const [success, setSuccess] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const currentAccount = bankAccounts[activeTab];
  const banks = ['HDFC Bank', 'SBI Bank'];
  const [error, setError] = useState(null);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };

  const handlePreviewSubmit = async () => {
    const payload = {
      id: 0,
      bankAccount: currentAccount.bank,
      paymentMode: formValues.paymentMode, // Ensure paymentMode is included
      amount: formValues.amount,
      transferDate: formValues.date.valueOf(),
      depositorMobile: formValues.mobileNumber,
      depositBranch: formValues.branch,
      utrReference: formValues.utr,
      acknowledgementSlip: formValues.acknowledgementSlip, // Assuming this is a string
      verified: "",
      remarks: formValues.remarks,
      isActive: "INACTIVE",
      createdAt: Date.now(),
      updatedAt: Date.now()
    };
  
    try {
      await fetcher.post(ApiUrls.SUBMIT_FUND_REQUEST, payload);
      setSuccess(true);
      formik.resetForm();
      setPreviewOpen(false);
    } catch (err) {
      setError(err.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      amount: '',
      utr: '',
      date: dayjs(),
      mobileNumber: '',
      branch: '',
      remarks: '',
      paymentMode: '', // Add paymentMode to initialValues
      acknowledgementSlip: '', // Add acknowledgementSlip to initialValues
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .min(10000, 'Amount must be at least ₹10,000.00')
        .max(10000000, 'Amount cannot exceed ₹1,00,00,000.00')
        .required('Amount is required'),
      utr: Yup.string().required('UTR/Reference Number is required'),
      date: Yup.date().required('Transfer date is required'),
      mobileNumber: Yup.string()
        .matches(/^\d{10}$/, 'Enter a valid 10-digit mobile number')
        .required('Mobile number is required'),
      branch: Yup.string().required('Deposit branch is required'),
      paymentMode: Yup.string().required('Payment mode is required'), // Add validation for paymentMode
      remarks: Yup.string(),
    }),
    onSubmit: (values) => {
      const isUtrDuplicate = transactions.some((txn) => txn.utr === values.utr);
      if (isUtrDuplicate) {
        formik.setFieldError('utr', 'The mentioned UTR already exists.');
        return;
      }
      setFormValues(values);
      setPreviewOpen(true);
    },
  });

  return (
    <Grid container spacing={2} padding={2}>
      {/* Left Section */}
      <Grid item xs={12} md={4}>
        <Paper elevation={3} style={{ padding: '16px' }}>
          <Typography variant="h6">New Fund Request</Typography>
          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Select Bank Account</InputLabel>
              <Select
                name="bankAccount"
                value={formik.values.bankAccount}
                onChange={formik.handleChange}
              >
                {banks.map((bank, index) => (
                  <MenuItem key={index} value={bank}>
                    {bank}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Select Payment Mode</InputLabel>
              <Select
                name="paymentMode"
                value={formik.values.paymentMode}
                onChange={formik.handleChange}
                error={formik.touched.paymentMode && Boolean(formik.errors.paymentMode)}
              >
                <MenuItem value="RTGS">RTGS</MenuItem>
                <MenuItem value="NEFT">NEFT</MenuItem>
              </Select>
              {formik.touched.paymentMode && formik.errors.paymentMode && (
                <Typography color="error">{formik.errors.paymentMode}</Typography>
              )}
            </FormControl>
            <TextField
              fullWidth
              label="Amount"
              variant="outlined"
              margin="normal"
              name="amount"
              value={formik.values.amount}
              onChange={formik.handleChange}
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
            />
            <TextField
              fullWidth
              label="UTR/Reference Number"
              variant="outlined"
              margin="normal"
              name="utr"
              value={formik.values.utr}
              onChange={formik.handleChange}
              error={formik.touched.utr && Boolean(formik.errors.utr)}
              helperText={formik.touched.utr && formik.errors.utr}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth margin="normal">
              <DatePicker
                label="Date of Transfer/Deposit"
                value={formik.values.date}
                onChange={(newValue) => formik.setFieldValue('date', newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={formik.touched.date && Boolean(formik.errors.date)}
                    helperText={formik.touched.date && formik.errors.date}
                  />
                )}
              />
            </LocalizationProvider>
            <TextField
              fullWidth
              label="Mobile Number of Depositor (Optional)"
              variant="outlined"
              margin="normal"
              name="mobileNumber"
              value={formik.values.mobileNumber}
              onChange={formik.handleChange}
              error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
              helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}
            />
            <TextField
              fullWidth
              label="Deposit Branch (Optional)"
              variant="outlined"
              margin="normal"
              name="branch"
              value={formik.values.branch}
              onChange={formik.handleChange}
              error={formik.touched.branch && Boolean(formik.errors.branch)}
              helperText={formik.touched.branch && formik.errors.branch}
            />
            <TextField
              fullWidth
              label="Deposit Slip"
              variant="outlined"
              margin="normal"
              name="acknowledgementSlip"
              value={formik.values.acknowledgementSlip}
              onChange={formik.handleChange}
              error={formik.touched.acknowledgementSlip && Boolean(formik.errors.acknowledgementSlip)}
              helperText={formik.touched.acknowledgementSlip && formik.errors.acknowledgementSlip}
            />
            <TextField
              fullWidth
              label="Remarks"
              variant="outlined"
              margin="normal"
              name="remarks"
              value={formik.values.remarks}
              onChange={formik.handleChange}
            />
            <Button variant="contained" color="primary" fullWidth type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Grid>

      <Grid item xs={12} md={8}>
        <Paper elevation={3} style={{ padding: '16px' }}>
          <Tabs value={activeTab} onChange={handleTabChange} textColor="primary" indicatorColor="primary">
            {bankAccounts.map((account, index) => (
              <Tab key={index} label={account.bank} />
            ))}
          </Tabs>
          <Box padding={2}>
            <Typography>
              <strong>Account Holder:</strong> {currentAccount.accountHolder}
            </Typography>
            <Typography>
              <strong>Account Number:</strong> {currentAccount.accountNumber}
            </Typography>
            <Typography>
              <strong>Account Type:</strong> {currentAccount.accountType}
            </Typography>
            <Typography>
              <strong>IFSC Code:</strong> {currentAccount.ifscCode}
            </Typography>
            <Typography>
              <strong>Branch:</strong> {currentAccount.branch}
            </Typography>
            <Typography>
              <strong>Minimum Amount:</strong> {currentAccount.minAmount}
            </Typography>
            <Typography>
              <strong>Allowed Modes:</strong> {currentAccount.allowedModes.join(', ')}
            </Typography>
          </Box>
        </Paper>
      </Grid>

      {/* Table Below Both Sections */}
      <Grid item xs={12}>
  <Paper elevation={3} style={{ padding: '16px' }}>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Transaction ID</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Branch</TableCell>
            <TableCell>Remarks</TableCell>
            <TableCell>Executer's Remark</TableCell>
            <TableCell>Execution Date & Time</TableCell>
            <TableCell>Transfer Date</TableCell>
            <TableCell>Branch UTR</TableCell>
            <TableCell>Commission/Charge</TableCell>
            <TableCell>Deposit Slip</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions
            .filter((txn) => txn.bank === banks[activeTab]) // Filter transactions based on active tab
            .map((txn, index) => (
              <TableRow key={index}>
                <TableCell>{txn.date}</TableCell>
                <TableCell>{txn.transactionID}</TableCell>
                <TableCell>{txn.amount}</TableCell>
                <TableCell>{txn.branch}</TableCell>
                <TableCell>{txn.remarks}</TableCell>
                <TableCell>{txn.executersRemark}</TableCell>
                <TableCell>{txn.executionDate} {txn.executionTime}</TableCell>
                <TableCell>{txn.transferDate}</TableCell>
                <TableCell>{txn.branchUTR}</TableCell>
                <TableCell>{txn.commissionCharge}</TableCell>
                <TableCell>{txn.depositSlip}</TableCell>
                <TableCell>{txn.status}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Paper>
</Grid>

      {/* Success Snackbar */}
      <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
        <Alert onClose={() => setSuccess(false)} severity="success">
          Fund request submitted successfully!
        </Alert>
      </Snackbar>
      {/* Preview Dialog */}
      <Dialog open={previewOpen} onClose={handlePreviewClose}>
        <DialogTitle>Preview Fund Request</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please review your fund request details before submitting.
          </DialogContentText>
          <Typography><strong>Amount:</strong> {formValues?.amount}</Typography>
          <Typography><strong>UTR/Reference Number:</strong> {formValues?.utr}</Typography>
          <Typography><strong>Date:</strong> {formValues?.date?.format('YYYY-MM-DD')}</Typography>
          <Typography><strong>Mobile Number:</strong> {formValues?.mobileNumber}</Typography>
          <Typography><strong>Branch:</strong> {formValues?.branch}</Typography>
          <Typography><strong>Remarks:</strong> {formValues?.remarks}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePreviewClose} color="primary">
            Modify
          </Button>
          <Button onClick={handlePreviewSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default FundRequest;
