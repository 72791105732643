import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import fetcher from '../../utils/fetcher';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SnackBar from '../../components/SnackBar';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ResetPasswordDialog from './ResetPassword.js'
import { isAuthenticated } from '../../utils/authGuard.js';

import { PageUrls } from '../../utils/constants';
import form from '../../assets/scss/form.module.scss';

import logoLogo from '../../assets/images/tramo-logo.svg';
import login from './Login.module.scss';
import { is } from 'date-fns/locale';

const Login = () => {
  const isAuthentic = isAuthenticated();
  const navigate = useNavigate();
  const navigateTo = () => {
    navigate(PageUrls.Dashboard);
  };

  const handleCloseResetPassword = () => {
    setOpen(false);
    
  };
  const [showPassword, setShowPassword] = useState(false);
  const [snackbar, setSnackbar] = useState({
    show: false,
    status: '',
    message: '',
  });
  const [open, setOpen] = useState(false)

  const toggleSnackbar = (value) => {
    setSnackbar(value);
  };



  const validationSchema = Yup.object().shape({
    userId: Yup.string().required('User ID is required'),
    password: Yup.string().required('Password is required'),
  });

  const handleSubmit = async (values) => {
    console.log('submitting');
    try {
      const res = await fetcher.post('/authenticate/user', values);
      setSnackbar({
        show: true,
        status: res?.status === 200 ? 'success' : 'error',
        message: res?.status === 200 ? 'Login successfully' : res?.message
      });
      if (res?.status === 200) {
        localStorage.setItem('auth', JSON.stringify(res.response));
        navigateTo();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      userId: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const token = localStorage.getItem('auth');
    console.log('Token in localStorage:', token); // Debugging statement
    if (isAuthentic) {
      console.log('Navigating to Dashboard'); // Debugging statement
      navigateTo(PageUrls.Dashboard); // Redirect if already logged in
    } else {
      console.log('No token found'); // Debugging statement
    }
  }, []);

  return (
    <>
      <SnackBar {...snackbar} onClose={toggleSnackbar} />

      <div className={login.background_color}>
        <div style={{ background: '#fff', padding: '20px', margin: '0 0 20px 0' }}>
          <div className="container-fluid">
            <img src={logoLogo} alt="Logo" />
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-sm-9 mx-auto">
              <div className={login.loginForm}>
                <div className="row">
                  <div className="col-sm-6">
                    <div className={login.login_left_space}>
                      <h1>Sign In</h1>

                      <div className="form-group">
                        <label className={login.label_control}>
                          User ID<span className={login.red}>*</span>
                        </label>
                        <input
                          name="userId"
                          onChange={formik.handleChange}
                          value={formik.values.userId}
                          type="text"
                          className="form-control"
                        />
                        {formik.touched.userId && formik.errors.userId && (
                          <div className="errorMsg">{formik.errors.userId}</div>
                        )}
                      </div>
                      <div className="form-group">
                        <label className={login.label_control}>
                          Password<span className={login.red}>*</span>
                        </label>
                        <div className={login.passwrordField}>
                          <input
                            name="password"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            type={showPassword ? 'text' : 'password'}
                            className="form-control"
                          />
                          <Button
                            type="button"
                            variant="text"
                            className={login.viewBtn}
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </Button>
                        </div>
                        {formik.touched.password && formik.errors.password && (
                          <div className="errorMsg">{formik.errors.password}</div>
                        )}
                      </div>
                      <button
                        type="submit"
                        className={login.submit_button}
                        onClick={formik.handleSubmit}
                      >
                        Sign In
                      </button>
                      <div className={login.forgotPassword} onClick={() => setOpen(true)}>
                        <a href="#">Forgot password?</a>
                        <ResetPasswordDialog open={open} onClose={handleCloseResetPassword} />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className={login.login_rgt_bg}>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
