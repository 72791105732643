import React, { useState ,useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { IconButton, InputAdornment, TextField, Button, Container, Typography, Box } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import fetcher from '../../utils/fetcher';
import { useNavigate, generatePath, useParams } from 'react-router-dom';

const ResetPasswordPage = () => {
       const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
      const params = useParams();
      const { token } = params
    useEffect(() => {
        if (token) {
            localStorage.removeItem("auth")
            localStorage.setItem('auth', JSON.stringify({ token: token }));
        }
    }, [token]);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const validationSchema = Yup.object({
        newPassword: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Required'),
        confirmNewPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
            .required('Required'),
    });

    const handleSubmit = async (values) => {
        try {
            const response = await fetcher.post('/reset-password/save', values);
            setSuccess(true);
            console.log(response.data);
            navigate(
                //  generatePath(PageUrls.CLIENT_AGREEMENT, { name: userData.authorizedPersonName, aadherNo: userData.leadAadhaarDetails.aadharNumber, phoneNo: userData.mobile }),
              )
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <Container maxWidth="xs">
            <Typography variant="h4" align="center" gutterBottom>
                Reset Password
            </Typography>
            <Formik
                initialValues={{ newPassword: '', confirmNewPassword: '' }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ handleChange, handleBlur, values }) => (
                    <Form>
                        <Box mb={2}>
                            <Field
                                as={TextField}
                                name="newPassword"
                                type={showPassword ? 'text' : 'password'}
                                label="New Password"
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.newPassword}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={togglePasswordVisibility}>
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <ErrorMessage name="newPassword" component="div" />
                        </Box>
                        <Box mb={2}>
                            <Field
                                as={TextField}
                                name="confirmNewPassword"
                                type={showPassword ? 'text' : 'password'}
                                label="Confirm New Password"
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.confirmNewPassword}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={togglePasswordVisibility}>
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <ErrorMessage name="confirmNewPassword" component="div" />
                        </Box>
                        <Box mt={2}>
                            <Button type="submit" color="primary" variant="contained" fullWidth>
                                Submit
                            </Button>
                        </Box>
                        {success && (
                            <Typography color="success" align="center" mt={2}>
                                Password reset successfully!
                            </Typography>
                        )}
                        {error && (
                            <Typography color="error" align="center" mt={2}>
                                {error}
                            </Typography>
                        )}
                    </Form>
                )}
            </Formik>
        </Container>
    );
};

export default ResetPasswordPage;