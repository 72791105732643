import React, { useRef } from 'react'
const ESignAgreementPreview = (props) => {
  const { sanitizedContent } = props
  const pdfRef = useRef(null);
  const handlePrint = () => {
    if (pdfRef.current) {
      // Clone the content to avoid modifying the actual DOM
      const contentToPrint = pdfRef.current.cloneNode(true);

      // Open a new window
      const printWindow = window.open("", "_blank");
      if (printWindow) {
        // Write the content to the new window
        printWindow.document.open();
        printWindow.document.write(`
                  <html>
                    <head>
                      <title>Print Preview</title>
                      <style>
                        /* Ensure styles are preserved */
                        body {
                          font-family: Arial, sans-serif;
                          margin: 0;
                          padding: 20px;
                        }
                        table {
                          width: 100%;
                          border-collapse: collapse;
                        }
                        th, td {
                          border: 1px solid black;
                          padding: 8px;
                          text-align: left;
                        }
                      </style>
                    </head>
                    <body>${contentToPrint.innerHTML}</body>
                  </html>
                `);
        printWindow.document.close();

        // Trigger the print dialog
        printWindow.print();

        // Close the print window
        printWindow.close();
      }
    }
  };

  // const handlePrint = async () => {
  //   if (pdfRef.current) {
  //     // Clone the content to avoid modifying the actual DOM
  //     const contentToPrint = pdfRef.current.cloneNode(true);

  //     // Initialize jsPDF with compression enabled
  //     const pdf = new jsPDF({
  //       orientation: "p",
  //       unit: "mm",
  //       format: "a4",
  //       compressPdf: true,
  //     });

  //     // Add HTML content to PDF
  //     pdf.html(contentToPrint, {
  //       callback: async (doc) => {
  //         // Generate the PDF blob
  //         const pdfBlob = doc.output("blob");

  //         // Define a file name for the PDF
  //         const fileName = `esignlead${leadId}.pdf`;

  //         // Upload the file to S3 bucket
  //         await handleFileUpload(fileName, leadId, pdfBlob);

  //         // Optional: Save locally for debugging
  //         // doc.save(fileName);
  //       },
  //       x: 10,
  //       y: 10,
  //       width: 190,
  //     });
  //   }
  // };
  // const blobToFile = async (theBlob, fileName) => {
  //   theBlob.lastModifiedDate = new Date();
  //   theBlob.name = fileName;
  //   return theBlob;
  // }
  // const handleFileUpload = async (fileName, leadUserId, pdfBlob) => {
  //   try {
  //     const file = await blobToFile(pdfBlob, fileName)
  //     const res = await fetcher.post(`${ApiUrls.GET_PREFETCH_URL}?fileName=${fileName}`);
  //     if (res.status === 200) {
  //       const uploadUrl = res.response;
  //       await fetcher.putFile(uploadUrl, file);
  //       // if (uploadRes.status === 200) {
  //       //   console.log("File uploaded successfully to S3");
  //       const response = await fetcher.post(ApiUrls.USER_ONBOARD)
  //       if (response.status === 200) {
  //         console.log("User is onbord");
  //       } else {
  //         console.error("Error while onboarding user");
  //       }
  //       // } else {
  //       //   console.error("Failed to upload file to S3", uploadRes.statusText);
  //       // }
  //     }
  //   } catch (err) {
  //     console.error("Error in file upload", err);
  //   }
  // };



  return (<>
    <button onClick={handlePrint}>Generate PDF</button>
    <div ref={pdfRef}>
      <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
    </div>
  </>

  )
}


export default ESignAgreementPreview